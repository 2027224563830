import { useState } from 'react'
import { MdEmail, MdExpandMore } from 'react-icons/md';
import { format } from 'date-fns';
import { BsFillTelephoneFill } from 'react-icons/bs';
import useDelete from '../../hook/useDelete';
import { delete_FamilyMember } from '../../services/FamilyMember';
import { delete_BulkFamilyMemberSubGroupByFamilyMemberId } from '../../services/FamilMemberSubGroup';
import useAuth from '../../hook/useAuth';
import baseURL from '../../config/api';

export enum CurrentPage {
  ADD_FAMILY,
  ADD,
  EDIT
};

type Props = {
  setCurrentPage: (value: CurrentPage) => void;
  familyMember: any[];
  setFamilyMember: (value: any[]) => void;
  setSelectedRowIndex: (value: number) => void;
  fromEdit?: boolean;
}

const AdminFamilyMemberList = ({ setCurrentPage, familyMember, setFamilyMember, setSelectedRowIndex, fromEdit = false }: Props) => {
  const { auth }: any = useAuth();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { setDeletePopup, setData: setDeleteData }: any = useDelete();

  const addFamilyMember = () => {
    setCurrentPage(CurrentPage.ADD)
  }

  const editFamilyMember = (index: number) => {
    setCurrentPage(CurrentPage.EDIT)
    setSelectedRowIndex(index);
  }

  const deleteFamilyMember = (id: string, index: number) => {
    if (fromEdit && parseInt(id) > 0) {
      setDeletePopup(true);
      setDeleteData({
        id: id,
        tilte: `Delete`,
        summary: `Are you sure you want to delete this family member?`,
        isDelete: (callback: any) => {
          delete_BulkFamilyMemberSubGroupByFamilyMemberId(id, auth?.id);
          delete_FamilyMember(id)
            .then(() => {
              setFamilyMember(familyMember.filter((item: any) => item?.id !== parseInt(id)));
              setDeletePopup(false);
              callback();
            })
            .catch(error => console.error(error));
        }
      });
    } else {
      setDeletePopup(true);
      setDeleteData({
        id: 0,
        tilte: `Delete`,
        summary: `Are you sure you want to delete this family member?`,
        isDelete: (callback: any) => {
          setFamilyMember(familyMember.filter((_: any, ind) => ind !== index))
          setDeletePopup(false);
          callback();
        }
      });
    }
  }

  return (
    <section className="user__container mt-16">
      <div className="min-w-full flex flex-row justify-between item-center text-xs sm:text-sm md:text-base"
        onClick={() => {
          setIsExpanded(!isExpanded)
        }
        }>
        <h1 className="px-4 py-1.5 flex items-center gap-2">
          <MdExpandMore className={`text-2xl transform duration-300 ${isExpanded ? "rotate-0" : "-rotate-90"}`} />
          <span className="sm:text-sm md:text-base lg:text-lg">Family Member</span>
        </h1>
        <button
          className="bg-blue-500 hover:bg-blue-700 px-4 py-1.5 rounded-sm text-white"
          onClick={addFamilyMember}
        >
          Add Family Member
        </button>
      </div>
      <div className="min-w-full h-0.5 bg-slate-500 my-1" />

      {isExpanded &&
        <>
          {familyMember.length > 0 ?
            <div className="member__list__container grid grid-cols-1 3lg:grid-cols-2 gap-y-4 lg:gap-x-4 m-auto w-full">
              {familyMember.map((item, index) => (
                <div key={index} className="member__view__container flex flex-col w-full h-fit gap-2 md:gap-3 rounded-lg py-2 bg-gray-50 shadow-2xl">
                  <div className="flex flex-col md:flex-row gap-2 md:gap-3 px-2">
                    <div className="member__view flex gap-2 md:gap-4 flex-col items-center">
                      <div className="image__view w-40 h-40">
                        <img
                          src={`${baseURL}commonFile/Get?name=${item.filePath}`} alt="family-member" className="w-40 h-40"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "./images/user-default.jpg";
                          }}
                        />
                      </div>
                    </div>

                    <div className="text__container flex flex-col flex-1 justify-center items-center w-full px-2">
                      <h1 className="name__text text-lg font-bold">{item.firstName + " " + item.lastName}</h1>
                      <h3 className="name__text text-sm font-semibold">{item?.birthday ? format(new Date(item?.birthday), "MM/dd/yyyy") : ""}</h3>
                    </div>

                    <div className="family__view flex flex-col-reverse md:flex-col min-w-[150px] lg:w-full">
                      <div className="flex gap-4 w-full flex-col lg:flex-row ">
                        <button
                          className="bg-blue-500 hover:bg-blue-700 px-4 py-1 rounded-sm font-bold text-white w-full"
                          onClick={() => editFamilyMember(index)}
                        >
                          Edit
                        </button>

                        <button
                          className="bg-blue-500 hover:bg-blue-700 px-4 py-1 rounded-sm font-bold text-white w-full"
                          onClick={() => deleteFamilyMember(item.id, index)}
                        >
                          Delete
                        </button>
                      </div>

                      <div className="text-black flex flex-col items-center md:items-start md:mt-4">
                        <div className="flex flex-row flex-1 justify-between items-center md:items-start gap-4 py-1">
                          <BsFillTelephoneFill />
                          <p className="flex flex-col flex-1 items-start text-base font-semibold">{item.phoneNumber}</p>
                        </div>

                        <div className="flex flex-row flex-1 justify-between items-center md:items-start gap-4 py-1">
                          <MdEmail />
                          <p className="flex flex-col flex-1 items-start text-base font-semibold">{item.emailAddress}</p>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>
              ))}
            </div>
            :
            (<div className="bg-blue-100 text-blue-800 0 pl-4 pr-10 py-4 rounded-md relative mt-4 md:mt-8" role="alert">
              <strong className="font-bold text-base">Info!</strong>
              <span className="block text-sm sm:inline max-sm:mt-1 max-sm:ml-0 mx-4">This is a info message that you need to add at least one family member</span>

            </div>)
          }
        </>
      }
    </section>
  )
}

export default AdminFamilyMemberList;