
const TermsOfService = () => {
  return (
    <div className="mx-auto p-6 sm:p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-6 text-center">Terms of Service</h1>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">1. Introduction</h2>
      <p className="text-gray-700 leading-relaxed mb-6">
        My Church Directory is a source for creating a directory. By using our service, you accept these Terms of Service.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">2. Membership</h2>
      <p className="text-gray-700 mb-4">
        Your free 30-day My Church Directory membership begins when you click "FREE TRIAL." During this trial, no credit card or payment is required. You can enjoy the full features of the directory for 30 days without any charges.
      </p>
      <p className="text-gray-700 mb-4">
        If you wish to continue using the service after your 30-day trial, you must upgrade to a paid subscription by providing your credit card information in the billing section of your account. If you do not upgrade, your membership will automatically cancel at the end of the trial period.
      </p>
      <p className="text-gray-700 mb-4">
        The FREE Trial offer cannot be combined with any other offers and is available only to first-time customers.
      </p>


      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">3. Promotional Offers</h2>
      <p className="text-gray-700 mb-4">
        We may offer special promotional offers at our discretion. Eligibility may vary, and conditions will be disclosed when signing up for the offer.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">4. Billing and Cancellation</h2>
      <p className="text-gray-700 mb-4">
        A valid credit card is required to purchase and continue a subscription. Charges occur upon clicking "Place Order." The billing cycle length depends on your selected subscription.
      </p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li>Your subscription will automatically continue until canceled.</li>
        <li>Payment is processed securely, and only the last four digits of your card will be visible on your account records.</li>
        <li>You can update your payment method under "Billing Section."</li>
      </ul>
      <p className="text-gray-700 mb-4">
        You can cancel your subscription anytime by following the instructions in "My Account."
      </p>
      <p className="text-gray-700 mb-4">
        We reserve the right to change subscription plans and pricing at any time.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">5. Content in the Services</h2>
      <p className="text-gray-700 mb-4">
        Communication Resources, Inc. owns all rights to My Church Directory and its services. You agree not to disrupt or interfere with the services.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">6. Copyright Policy</h2>
      <p className="text-gray-700 mb-4">
        My Church Directory can be reproduced for use within your church or organization. By accepting these Terms, you agree to the stated Copyright Policy.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">7. Consent to Paperless Billing Invoices</h2>
      <p className="text-gray-700 mb-4">
        By opting in, you consent to receive email notifications of invoices and agree to the following:
      </p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li>Receive notifications for invoice availability.</li>
        <li>Keep your email address up-to-date.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">8. Push Notifications</h2>
      <p className="text-gray-700 mb-4">
        By using My Church Directory, you consent to receive push notifications related to your account and the services provided. These notifications may include updates, reminders, and important announcements.
      </p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li>
          <strong>Opt-In:</strong> You will be asked to opt-in to receive push notifications in the app settings.
        </li>
        <li>
          <strong>Notification Types:</strong> Notifications may include messages about upcoming events, directory updates, and other relevant information.
        </li>
        <li>
          <strong>Manage Notifications:</strong> You can manage your notification preferences at any time through the app settings. This includes the ability to disable push notifications.
        </li>
      </ul>
      <p className="text-gray-700 mb-6">
        If you have any questions or concerns about push notifications, please contact us for assistance.
      </p>
    </div>
  );
};

export default TermsOfService;