import baseURL from "../config/api";
import axios from "../config/axios";

export const post_BulkFamilyMemberSubGroup = async (data: any[]) => {
    const response = await axios({
        url: `${baseURL}familyMemberSubGroup/CreateFamilyMemberSubGroups`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const put_BulkFamilyMemberSubGroup = async (data: any[]) => {
    const response = await axios({
        url: `${baseURL}familyMemberSubGroup/UpdateFamilyMemberSubGroups`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    });
    return response.data;
}

export const delete_BulkFamilyMemberSubGroupByFamilyMemberId = async (familyMemberId: string, userId: number) => {
    const response = await axios({
        url: `${baseURL}familyMemberSubGroup/DeleteFamilyMemberSubGroupWithFamilyMemberId/${familyMemberId},${userId}`,
        method: "DELETE"
    });
    return response.data;
}

export const getFamilyMemberSubGroupByFamilyMemberId = (familyMemberId: string) => {
    return getFetch(`familyMemberSubGroup/GetFamilyMemberSubGroupsByFamilyMemberId/${familyMemberId}`);
}

const getFetch = async (url: string, params = {}) => {
    const response = await axios({
        url: baseURL + url,
        method: "GET",
        params: params
    });
    return response.data;
}