import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { FileDrop } from '../../components/FileDrop';
import { onChangePhoneNumber } from '../../utils/phoneNumberRegex';
import { getGroups } from '../../services/Group';
import baseURL from '../../config/api';
import Cookies from 'js-cookie';
import { post_SubGroup } from '../../services/SubGroup';

const AddSubGroup = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [subGroupData, setSubGroupData] = useState<any>({
        name: "",
        discription: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        imagePath: "",
        street: "",
        street2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        isActive: true,
        groupId: 0,
    });
    const [groupData, setGroupData] = useState<any[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
    const groupIdAuth = Cookies.get("groupId-my-cdir") || "";
    const role = Cookies.get("role-my-cdir") || "";

    useEffect(() => {
        if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "admin") {
            setIsSuperAdmin(false);
        } else if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "superadmin") {
            setIsSuperAdmin(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "superadmin") {
            getGroups()
                .then(res => {
                    setGroupData(res.map((item: any) => {
                        return {
                            id: item.id,
                            name: item.name
                        }
                    }));

                    if (state) {
                        setSubGroupData({ ...subGroupData, groupId: state });
                    }
                })
                .catch(error => console.log(error));
        } else if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "admin") {
            setSubGroupData({ ...subGroupData, groupId: parseInt(groupIdAuth) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeModal = () => navigate(-1);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isProcessing) {
            setIsProcessing(true);

            post_SubGroup({
                ...subGroupData,
                imagePath: subGroupData?.imagePath?.replace(`${baseURL}commonFile/Get?name=`, ""),
            })
                .then(() => {
                    setIsProcessing(false);
                    navigate(-1);
                })
                .catch(error => {
                    setIsProcessing(false);
                    console.error(error);
                });
        }
    }


    return (
        <div className="w-full">
            <div className="my-2">
                <div className="mx-auto max-w-6xl  relative bg-white">
                    <h2 className="text-xl text-blue-500 font-bold">Add SubGroup</h2>
                    <form onSubmit={handleSubmit} className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
                        {isSuperAdmin &&
                            <div>
                                <label className="font-semibold text-sm">Select Church/Organization</label>
                                <select
                                    className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                    value={subGroupData.groupId}
                                    onChange={(e) => setSubGroupData({ ...subGroupData, groupId: parseInt(e.target.value) })}
                                    required
                                >
                                    {groupData.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <div>
                            <label className="font-semibold text-sm">SubGroup Name</label>
                            <input type='text' placeholder='Enter SubGroup Name'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.name}
                                onChange={(e) => setSubGroupData({ ...subGroupData, name: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">First Name</label>
                            <input type='text' placeholder='Enter First Name'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.firstName}
                                onChange={(e) => setSubGroupData({ ...subGroupData, firstName: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Last Name</label>
                            <input type='text' placeholder='Enter Last Name'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.lastName}
                                onChange={(e) => setSubGroupData({ ...subGroupData, lastName: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Email</label>
                            <input type='email' placeholder='Email'
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.emailAddress}
                                onChange={(e) => setSubGroupData({ ...subGroupData, emailAddress: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Phone Number</label>
                            <input type='text' placeholder='Phone No.'
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                                value={subGroupData.phoneNumber}
                                onChange={(e) => setSubGroupData({ ...subGroupData, phoneNumber: onChangePhoneNumber(e.target.value) })}
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Street</label>
                            <input type='text' placeholder='Street'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.street}
                                onChange={(e) => setSubGroupData({ ...subGroupData, street: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Street 2</label>
                            <input type='text' placeholder='Street 2'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.street2}
                                onChange={(e) => setSubGroupData({ ...subGroupData, street2: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">City</label>
                            <input type='text' placeholder='City'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.city}
                                onChange={(e) => setSubGroupData({ ...subGroupData, city: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">State</label>
                            <input type='text' placeholder='State'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.state}
                                onChange={(e) => setSubGroupData({ ...subGroupData, state: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Country</label>
                            <input type='text' placeholder='Country'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.country}
                                onChange={(e) => setSubGroupData({ ...subGroupData, country: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Zip</label>
                            <input type='text' placeholder='Zip'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.zip}
                                onChange={(e) => setSubGroupData({ ...subGroupData, zip: e.target.value })}
                                required
                            />
                        </div>

                        {!isSuperAdmin && <div />}

                        <div className='h-5/6'>
                            <label className="font-semibold text-sm">Description</label>
                            <textarea placeholder='Enter Description'
                                className="w-full h-full rounded py-2.5 px-4 mt-3 border-2 text-sm text-black outline-[#007bff]"
                                value={subGroupData.discription}
                                onChange={(e) => setSubGroupData({ ...subGroupData, discription: e.target.value })}
                            />
                        </div>
                        <div>
                            <label htmlFor="active" className="font-semibold text-sm">
                                Active?
                            </label>{" "}
                            <div className="flex flex-row">
                                <div className="flex items-center mr-2">
                                    <input
                                        type="radio"
                                        value="Yes"
                                        name="active"
                                        style={{ fontSize: "12px" }}
                                        checked={subGroupData.isActive ? true : false}
                                        onChange={(e) => setSubGroupData({ ...subGroupData, isActive: e.target.value === "Yes" ? true : false })}
                                    />
                                    <label className="ml-1">Yes</label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        value="No"
                                        name="active"
                                        style={{ fontSize: "12px" }}
                                        checked={subGroupData.isActive ? false : true}
                                        onChange={(e) => setSubGroupData({ ...subGroupData, isActive: e.target.value === "Yes" ? true : false })}
                                    />
                                    <label className="ml-1">No</label>
                                </div>
                            </div>
                        </div>
                                             
                        <div className='h-2/5 mt-4'>
                            <FileDrop
                                value={subGroupData.imagePath}
                                setValue={(value) => setSubGroupData({ ...subGroupData, imagePath: value })}
                            />
                        </div>
                        <div />

                        <div className="flex max-sm:flex-col gap-4 !mt-4">
                            <button
                                type="button"
                                className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-6 py-2 min-w-[150px] rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
                            >
                                {isProcessing ?
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                                            viewBox="0 0 26.349 26.35">
                                            <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                                            <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                                            <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                                            <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                                            <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                                            <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                                            <path
                                                d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                                                data-original="#000000" />
                                            <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                                        </svg>
                                        Processing...
                                    </>
                                    :
                                    "Save"
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddSubGroup