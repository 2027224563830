import { useState, useEffect } from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { format } from 'date-fns';
import baseURL from '../../config/api';
import Cookies from 'js-cookie';
import { AiOutlineSearch } from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { RiArrowDownSFill } from 'react-icons/ri';

const MemberList = () => {
    const navigate = useNavigate();
    const groupId = Cookies.get('groupId-my-cdir') || '0';
    const [searchTableData, setSearchTableData] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [memberFilterValue, setMemberFilterValue] = useState(0);

    const groupName = Cookies.get("groupName-my-cdir") || "";
    const groupImage = Cookies.get("groupImage-my-cdir") || "";

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5,
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [selectedFilter, setSelectedFilter] = useState<string>("Member Name");
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
    const [showEntries, setShowEntries] = useState<number>(5);


    const seeFamilyMember = (id: string, name: string) => {
        navigate(`/member/family-members/${id}/${name}`)
    }

    useEffect(() => {
        if (searchValue === "" && !isFirstTime) {
            const newPaginationModel = {
                page: 0,
                size: paginationModel.size,
            };

            setPaginationModel(newPaginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue === "") {
            setIsFirstTime(false)
            apiCall(paginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const apiCall = (newPaginationModel: { page: number, size: number }) => {
        if (parseInt(groupId) > 0) {
            setIsDataLoaded(true);
            fetch(`${baseURL}member/GetMemberReportByGroupIdPaging/${parseInt(groupId)},${newPaginationModel.page},${newPaginationModel.size}`)
                .then((res) => res.json())
                .then((data) => {
                    const result = data.memberReport.map((item: any) => {
                        return {
                            ...item,
                            name: item.firstName + " " + item.lastName,
                            birthday: item?.birthday ? format(new Date(item?.birthday), "MM/dd/yyyy") : "No data",
                            imagePath: item?.imagePath ? `${baseURL}commonFile/Get?name=${item.imagePath}` : "./images/user-default.jpg",
                            address: `${item?.street}, ${item?.city} ${item?.state}, ${item?.country}-${item?.zip}`,
                        };
                    })

                    setSearchTableData(result);
                    setTotalLength(data.count);
                    setIsDataLoaded(false);

                }).catch((error) => {
                    console.log(error);
                    setIsDataLoaded(false);
                    setSearchTableData([]);
                    setTotalLength(0);
                });
        }
        else {
            setIsDataLoaded(false);
            setSearchTableData([]);
            setTotalLength(0);
        }

    }


    useEffect(() => {
        let timeOutId: NodeJS.Timeout;

        if (searchValue !== "") {
            timeOutId = setTimeout(() => {
                const newPaginationModel = {
                    page: 0,
                    size: paginationModel.size,
                };

                setPaginationModel(newPaginationModel);

            }, 1000);
        }

        return () => clearTimeout(timeOutId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue !== "") {
            filteApiCall(paginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const filteApiCall = (newPaginationModel: { page: number, size: number }) => {
        let filterData: any[] = [];
        setIsDataLoaded(true);
        switch (memberFilterValue) {
            case 0:
                fetch(
                    `${baseURL}member/GetMemberReportByGroupIdAndSearchWithMemberName/${searchValue},${parseInt(groupId)},${newPaginationModel.page},${newPaginationModel.size}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        filterData = data.memberReport.map((item: any) => {
                            return {
                                ...item,
                                name: item.firstName + " " + item.lastName,
                                birthday: item?.birthday ? format(new Date(item?.birthday), "MM/dd/yyyy") : "No data",
                                imagePath: item?.imagePath ? `${baseURL}commonFile/Get?name=${item.imagePath}` : "./images/user-default.jpg",
                                address: `${item?.street}, ${item?.city} ${item?.state}, ${item?.country}-${item?.zip}`,
                            };
                        })
                        setSearchTableData(filterData);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsDataLoaded(false);
                        setSearchTableData([]);
                        setTotalLength(0);
                    });

                break;
            case 1:
                fetch(
                    `${baseURL}member/GetMemberReportByGroupIdAndSearchWithFamilyName/${searchValue},${parseInt(groupId)},${newPaginationModel.page},${newPaginationModel.size}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        filterData = data.memberReport.map((item: any) => {
                            return {
                                ...item,
                                name: item.firstName + " " + item.lastName,
                                birthday: item?.birthday ? format(new Date(item?.birthday), "MM/dd/yyyy") : "No data",
                                imagePath: item?.imagePath ? `${baseURL}commonFile/Get?name=${item.imagePath}` : "./images/user-default.jpg",
                                address: `${item?.street}, ${item?.city} ${item?.state}, ${item?.country}-${item?.zip}`,
                            };
                        })

                        setSearchTableData(filterData);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsDataLoaded(false);
                        setSearchTableData([]);
                        setTotalLength(0);
                    });

                break;
            case 2:
                fetch(
                    `${baseURL}member/GetMemberReportByGroupIdAndSearchWithEmailAddress/${searchValue},${parseInt(groupId)},${newPaginationModel.page},${newPaginationModel.size}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        filterData = data.memberReport.map((item: any) => {
                            return {
                                ...item,
                                name: item.firstName + " " + item.lastName,
                                birthday: item?.birthday ? format(new Date(item?.birthday), "MM/dd/yyyy") : "No data",
                                imagePath: item?.imagePath ? `${baseURL}commonFile/Get?name=${item.imagePath}` : "./images/user-default.jpg",
                                address: `${item?.street}, ${item?.city} ${item?.state}, ${item?.country}-${item?.zip}`,
                            };
                        })

                        setSearchTableData(filterData);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsDataLoaded(false);
                        setSearchTableData([]);
                        setTotalLength(0);
                    });

                break;

        }
    }

    const applyFilter = () => {
        setSelectedFilter(() =>
            memberFilterValue === 0 ? "Member Name" : memberFilterValue === 1 ? "Family Name" : "Email"
        );
        setSearchValue("");
        setDisplayFilter(false);
    };

    const clearFilter = () => {
        setSearchValue("");
        setDisplayFilter(false);
    };

    return (
        <section className="members__container">
            <div className="flex flex-col justify-between items-center">
                <img
                    src={groupImage} width={275} alt={groupName} height={275} className="rounded-lg"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "./images/img_placeholder1.jpg";
                    }}
                />
                <h1 className="font-bold text-lg py-2">{groupName}</h1>
            </div>
            <div className='py-4'>

                <div className='max-w-screen-sm mx-auto rounded-lg flex space-x-3 justify-start items-center border py-3 pl-8 shadow-md'>
                    <div className='relative'>
                        <button
                            className="bg-blue-500 text-white px-3 py-1 rounded-md flex items-center hover:bg-blue-700 focus:outline-none"
                            onClick={() => setDisplayFilter(!displayFilter)}
                        >
                            {selectedFilter}
                            <RiArrowDownSFill className="ml-1" />
                        </button>

                        <div
                            className={`${displayFilter ? "block" : "hidden"
                                } absolute w-40 ml-1 p-2 bg-white border border-black top-0 left-full`}
                        >
                            <div className="flex justify-between">
                                <label className="text-xs font-bold" htmlFor="fields">
                                    Fields
                                    <br />
                                </label>
                                <div
                                    onClick={() => setDisplayFilter(false)}
                                    className="w-4 h-4 cursor-pointer text-sm text-center items-center"
                                >
                                    <RxCross1 />
                                </div>
                            </div>
                            <select
                                className="w-full text-xs py-1 border mt-1"
                                name="fields"
                                value={memberFilterValue}
                                onChange={(e) =>
                                    setMemberFilterValue(parseInt(e.target.value))
                                }
                            >
                                <option value="0">Member Name</option>
                                <option value="1">Family Name</option>
                                <option value="2">Email</option>

                            </select>

                            <div className='flex mt-2 justify-between whitespace-nowrap'>
                                <button
                                    className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                                    onClick={() => applyFilter()}
                                >
                                    Apply Filter
                                </button>

                                <button
                                    className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                                    onClick={() => clearFilter()}
                                >
                                    Clear
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className='ml-auto w-full'>
                        <div className='flex items-center gap-2 w-full'>
                            <AiOutlineSearch className=' text-gray-500 cursor-pointer' style={{ width: "60px" }} />
                            <input
                                type='text'
                                placeholder='Search…'
                                className='py-1 text-base pl-2 focus:outline-none w-full mr-6'
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4" />
            {!isDataLoaded ?
                (
                    <>
                        {searchTableData.length > 0 ?
                            (
                                <div className="member__list__container grid grid-cols-1 3lg:grid-cols-2 gap-y-4 lg:gap-x-8 text-center m-auto w-full">
                                    {
                                        searchTableData.slice(0, showEntries).map((item, index) => (
                                            <div key={index} className="member__view__container flex flex-col flex-1 sm:flex-row w-full h-fit md:gap-8 bg-gray-50 shadow-2xl rounded-md p-2">
                                                <div className="member__view flex gap-2 md:gap-4 w-full md:w-4/6">
                                                    <div className="image__view w-40 h-40">
                                                        <img
                                                            src={item.imagePath} alt="member" className="w-full h-full"
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = "./images/user-default.jpg";
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="text__container flex flex-col flex-1 justify-between py-4">
                                                        <div className="text-start">
                                                            <h1 className="name__text text-lg font-bold">{item.familyName}</h1>

                                                            <h3 className="name__text text-sm font-semibold">{item.name}</h3>
                                                        </div>

                                                        <div className="text-start flex-nowrap">
                                                            <p className="name__text text-sm">{item.address}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="family__view flex flex-col-reverse md:flex-col justify-between py-4 min-w-[150px] 3lg:w-fit w-full md:w-2/6">
                                                    <button
                                                        className="bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded-sm font-bold text-white w-full"
                                                        onClick={() => seeFamilyMember(item.id, item.name)}
                                                    >
                                                        See Family
                                                    </button>

                                                    <div className="text-black text-start">
                                                        <div className="flex flex-row flex-1 justify-start items-center gap-2 py-1 w-full flex-wrap">
                                                            <BsFillTelephoneFill size={15} />
                                                            <p className="flex flex-col flex-1 justify-start text-center items-start text-base font-semibold flex-nowrap overflow-hidden overflow-ellipsis">{item.phoneNumber}</p>
                                                        </div>

                                                        <div className="flex flex-row flex-1 justify-start items-center gap-2 py-1 w-full flex-wrap">
                                                            <MdEmail size={15} />
                                                            <p className="flex flex-col flex-1 justify-start text-center items-start text-base font-semibold flex-nowrap overflow-hidden overflow-ellipsis">{item.emailAddress}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <div id="loading-colors" className="min-w-full h-[80px] flex flex-col flex-1 justify-center items-center">
                                    <div className="flex flex-col items-center">
                                        <span className="text-black">No Data Found</span>
                                    </div>
                                </div>
                            )}

                        {
                            totalLength > 0 && (
                                <Pagination
                                    pageOption={[5, 10, 15, 20, 25, 50, 75, 100]}
                                    totalLength={totalLength}
                                    paginationModel={paginationModel}
                                    setPaginationModel={setPaginationModel}
                                    showEntries={showEntries}
                                    setShowEntries={setShowEntries}
                                />
                            )
                        }

                    </>
                )
                :
                (
                    <div id="loading-colors" className="min-w-full h-[480px] flex flex-col flex-1 justify-center items-center">
                        <div className="flex flex-col items-center">
                            <div
                                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
                                role="status"></div>
                            <span className="text-black">Loading...</span>
                        </div>
                    </div>
                )
            }

        </section>
    );
};

export default MemberList;