import { HashRouter as Router, Routes, Route } from "react-router-dom";
import './styles/styles.css';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import { ActiveNavBarProvider } from './context/ActiveNavBarProvider';
import Footer from "./components/Footer";
import Home from "./pages/home/Home";
import ChurchDirectoryFeatures from "./pages/features/ChurchDirectoryFeatures";
import MemberMobileApp from "./pages/features/MemberMobileApp";
import OnlineMemberDirectory from "./pages/features/OnlineMemberDirectory";
import TextAndEmailMessaging from "./pages/features/TextAndEmailMessaging";
import PremiumService from "./pages/features/PremiumService";
import Security from "./pages/resources/Security";
import Demos from "./pages/resources/Demos";
import Blog from "./pages/resources/Blog";
import Tools from "./pages/resources/Tools";
import ContactUs from "./pages/resources/ContactUs";
import Pricing from "./pages/pricing/Pricing";
import Features from "./pages/features/Features";
import TermsOfService from "./pages/importantLinks/TermsOfService";
import PrivacyPolicy from "./pages/importantLinks/PrivacyPolicy";
import Help from "./pages/importantLinks/Help";
import MemberSignIn from "./pages/signIn/MemberSignIn";
import AdminSignIn from "./pages/signIn/AdminSignIn";
import FreeTrial from "./pages/FreeTrial";
import AdminMainLayout from "./components/AdminMainLayout";
import MemberMainLayout from "./components/MemberMainLayout";
import Church from "./pages/church/Church";
import User from "./pages/user/User";
import SubGroup from "./pages/subgroup/SubGroup";
import PaymentGateway from "./pages/Payment/PaymentGateway";
import AddUser from "./pages/user/AddUser";
import EditUser from "./pages/user/EditUser";
import DeleteUser from "./pages/user/DeleteUser";
import { AuthProvider } from "./context/AuthProvider";
import RequriedAuth from "./components/RequriedAuth";
import PageNotFound from "./components/PageNotFound";
import NotFound from "./components/NotFound";
import AdminMyAccount from "./pages/account/AdminMyAccount";
import AddChurch from "./pages/church/AddChurch";
import EditChurch from "./pages/church/EditChurch";
import DeleteChurch from "./pages/church/DeleteChurch";
import { UserRoles } from "./pages/user/UserRoles";
import RequriedAuthForMember from "./components/RequriedAuthForMember";
import Checkout from "./pages/pricing/Checkout";
import MemberList from "./pages/member/MemberList";
import FamilyMember from "./pages/member/FamilyMember";
import DeleteFamilyMember from "./pages/member/DeleteFamilyMember";
import EditFamilyMember from "./pages/member/EditFamilyMember";
import MemberMyAccount from "./pages/member/MemberMyAccount";
import AddFamilyMember from "./pages/member/AddFamilyMember";
import AdminMemberList from "./pages/admin/AdminMemberList";
import DeleteAdminMember from "./pages/admin/DeleteAdminMember";
import DeleteAdminFamilyMember from "./pages/admin/DeleteAdminFamilyMember";
import AddAdminMember from "./pages/admin/AddAdminMember";
import EditAdminMember from "./pages/admin/EditAdminMember";
import ThankYou from "./pages/pricing/ThankYou";
import { CreditCards } from "./pages/Payment/creditCards/CreditCards";
import Faq from "./pages/faq/Faq";
import AddFaq from "./pages/faq/AddFaq";
import DeleteFaq from "./pages/faq/DeleteFaq";
import EditFaq from "./pages/faq/EditFaq";
import AddSubGroup from "./pages/subgroup/AddSubGroup";
import DeleteSubGroup from "./pages/subgroup/DeleteSubGroup";
import EditSubGroup from "./pages/subgroup/EditSubGroup";
import Event from "./pages/event/Event";
import UnAuthorized from "./components/UnAuthorized";
import DeleteEvent from "./pages/event/DeleteEvent";
import AddEvent from "./pages/event/AddEvent";
import EditEvent from "./pages/event/EditEvent";
import AllFaq from "./pages/faq/AllFaq";
import AdminFaq from "./pages/faq/AdminFaq";
import MemberFaq from "./pages/faq/MemberFaq";
import FamilyMembers from "./pages/familyMembers/FamilyMembers";
import AddFamilyMembers from "./pages/familyMembers/AddFamilyMembers";
import EditFamilyMembers from "./pages/familyMembers/EditFamilyMembers";
import DeleteFamilyMembers from "./pages/familyMembers/DeleteFamilyMembers";
import AdminForgotPassword from "./pages/signIn/AdminForgotPassword";
import MemberForgotPassword from "./pages/signIn/MemberForgotPassword";
import LoginDevices from "./pages/support/LoginDevices";
import DeleteDevice from "./pages/support/DeleteDevice";
import EffortlesslyCreateAndMaintainChurchDirectory from "./pages/resources/EffortlesslyCreateAndMaintainChurchDirectory";
import TopBenefitsOfMyChurchDirectory from "./pages/resources/TopBenefitsOfMyChurchDirectory";
import SimplifyChurchAdministration from "./pages/resources/SimplifyChurchAdministration";
import Billing from "./pages/billing/Billing";
import { DeleteProvider } from "./context/DeleteProvider";
import BillPay from "./pages/billpay/BillPay";
import BillPayDetails from "./pages/billpay/BillPayDetails";
import QuickPayView from "./pages/billpay/QuickPayView";
import NewSupportTicket from "./pages/support/tickets/NewSupportTicket";
import SupportTickets from "./pages/support/tickets/SupportTickets";
import EditSupportTicket from "./pages/support/tickets/EditSupportTicket";
import { AttachmentProvider } from "./context/AttachmentProvider";

const Roles = {
  SuperAdmin: "SuperAdmin",
  Admin: "Admin",
  Member: "Member"
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <DeleteProvider>
          <ActiveNavBarProvider>
            <AttachmentProvider>
              <Header />
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/not-found" element={<PageNotFound />} />
                <Route path="/un-authorized" element={<UnAuthorized />} />

                {/* BasicRoutes */}
                <Route path="/" element={<Home />} />
                <Route path="/features" element={<Features />} />
                <Route path="/church-directory-features" element={<ChurchDirectoryFeatures />} />
                <Route path="/mobile-app" element={<MemberMobileApp />} />
                <Route path="/online-member-directory" element={<OnlineMemberDirectory />} />
                <Route path="/text-and-email-messaging" element={<TextAndEmailMessaging />} />
                <Route path="/premium-service" element={<PremiumService />} />
                <Route path="/security" element={<Security />} />
                <Route path="/demos" element={<Demos />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/effortlessly-create-and-maintain-church-directory" element={<EffortlesslyCreateAndMaintainChurchDirectory />} />
                <Route path="/blog/top-benifits-of-my-church-directory" element={<TopBenefitsOfMyChurchDirectory />} />
                <Route path="/blog/simplify-church-administration" element={<SimplifyChurchAdministration />} />
                <Route path="/tools" element={<Tools />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/help" element={<Help />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/faq" element={<AllFaq />} />

                <Route path="/bill-pay" element={<BillPay />} />
                <Route path="/quick-pay/:accountNumber" element={<QuickPayView />} />
                <Route path="/bill-details" element={<BillPayDetails />} />
                <Route path="/free-trial" element={<FreeTrial />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/checkout/thank-you" element={<ThankYou />} />

                {/* AdminRoutes */}
                <Route path="/sign-in-admin" element={<AdminSignIn />} />
                <Route path="/forgot-password" element={<AdminForgotPassword />} />
                <Route element={<RequriedAuth allowedRoles={[Roles.SuperAdmin, Roles.Admin]} />}>
                  <Route path="admin" element={<AdminMainLayout />}>
                    <Route path="user" element={<User />}>
                      <Route path="addUser" element={<AddUser />} />
                      <Route path="editUser/:id" element={<EditUser />} />
                      <Route path="deleteUser/:id" element={<DeleteUser />} />
                    </Route>

                    <Route path="user-roles" element={<UserRoles />} />

                    <Route path="church" element={<Church />} >
                      <Route path="deleteChurch/:id" element={<DeleteChurch />} />
                    </Route>
                    <Route path="church/addChurch" element={<AddChurch />} />
                    <Route path="church/editChurch/:id" element={<EditChurch />} />

                    <Route path="subgroup" element={<SubGroup />}>
                      <Route path="deleteSubGroup/:id" element={<DeleteSubGroup />} />
                    </Route>
                    <Route path="subgroup/addSubGroup" element={<AddSubGroup />} />
                    <Route path="subgroup/editSubGroup/:id" element={<EditSubGroup />} />

                    <Route path="member" element={<AdminMemberList />} >
                      <Route path="deleteMember/:id" element={<DeleteAdminMember />} />
                    </Route>
                    <Route path="member/addMember" element={<AddAdminMember />} />
                    <Route path="member/editMember/:id" element={<EditAdminMember />} >
                      <Route path="deleteFamilyMember/:deleteid" element={<DeleteAdminFamilyMember />} />
                    </Route>
                    <Route path="family-members" element={<FamilyMembers />} >
                      <Route path="deleteFamilyMember/:deleteid" element={<DeleteFamilyMembers />} />
                    </Route>
                    <Route path="familyMembers/addFamilyMember" element={<AddFamilyMembers />} />
                    <Route path="familyMembers/editFamilyMember/:id" element={<EditFamilyMembers />} />

                    <Route path="event" element={<Event />} >
                      <Route path="deleteEvent/:id" element={<DeleteEvent />} />
                    </Route>
                    <Route path="event/addEvent" element={<AddEvent />} />
                    <Route path="event/editEvent/:id" element={<EditEvent />} />

                    <Route path="faq" element={<Faq />} />
                    <Route path="/admin/faq/addFaq" element={<AddFaq />} />
                    <Route path="/admin/faq/deleteFaq/:id" element={<DeleteFaq />} />
                    <Route path="/admin/faq/editFaq/:id" element={<EditFaq />} />


                    <Route path="payment-gateway" element={<PaymentGateway />} />
                    <Route path="credit-cards" element={<CreditCards />} />

                    <Route path="admin-f-a-q" element={<AdminFaq />} />

                    <Route path="login-devices" element={<LoginDevices />} >
                      <Route path="deleteDevice/:id" element={<DeleteDevice />} />
                    </Route>

                    <Route path="tickets" element={<SupportTickets />} />
                    <Route path="tickets/newsupportticket" element={<NewSupportTicket />} />
                    <Route path="tickets/editsupportticket/:id" element={<EditSupportTicket />} />

                    <Route path="billing" element={<Billing />} />

                    <Route path="my-account" element={<AdminMyAccount />} />
                  </Route>
                </Route>

                {/* MemberRoutes */}
                <Route path="/sign-in" element={<MemberSignIn />} />
                <Route path="/forgot-password-member" element={<MemberForgotPassword />} />
                <Route element={<RequriedAuthForMember allowedRoles={[Roles.Member]} />} >
                  <Route path="member" element={<MemberMainLayout />}>
                    <Route path="members" element={<MemberList />} />
                    <Route path="family-members/:id/:name" element={<FamilyMember />} />

                    <Route path="family-members/add-family-members" element={<AddFamilyMember />} />
                    <Route path="family-members/edit-family-members/:id" element={<EditFamilyMember />} />

                    <Route path="member-f-a-q" element={<MemberFaq />} />

                    <Route path="my-account" element={<MemberMyAccount />} >
                      <Route path="delete-family-members/:id" element={<DeleteFamilyMember />} />
                    </Route>
                  </Route>
                </Route>

              </Routes>
              <Footer />
            </AttachmentProvider>
          </ActiveNavBarProvider>
        </DeleteProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
