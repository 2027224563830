import { useEffect, useState } from 'react'
import { MdOutlineSupportAgent } from "react-icons/md";
import { IoIosAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { RxCross1 } from 'react-icons/rx';
import { BsSearch } from 'react-icons/bs';
import { RiArrowDownSFill } from 'react-icons/ri';
import Pagination from '../../../components/Pagination';
import { getTicketStatusesByAttribute } from '../../../services/TicketStatus';
import { appName } from '../../../config/api';
import { getIncidentsReportByAppGroupIdAndByAppName, getIncidentsReportByAppGroupIdAndByAppNameAndSearchWithIncidentName, getIncidentsReportByAppGroupIdAndByAppNameWithStatus, getIncidentsReportByAppGroupIdAndByAppNameWithStatusAndSearchWithIncidentName } from '../../../services/Tickets';

const heading = ["ID", "Subject", "Assigned Agent", "Created Date", "Status", "Actions"];

const SupportTickets = () => {
  const navigate = useNavigate();
  const appGroupId = Cookies.get("groupId-my-cdir") || "";
  const [showEntries, setShowEntries] = useState<number>(5);
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [eventFilterValue, setEventFilterValue] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
    page: 0,
    size: 5,
  });
  const [totalLength, setTotalLength] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<string>("Subject");
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [statusData, setStatusData] = useState<{ value: string, label: string }[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("All");

  useEffect(() => {
    if (!isFirstTime) {
      setSelectedFilter("Subject");
      setEventFilterValue(0);
      if (searchValue !== "") {
        setSearchValue("");
        setIsFirstTime(false);
      }
      else {
        setPaginationModel({
          page: 0,
          size: 5
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isFirstTime) {
      setPaginationModel({
        page: 0,
        size: 5
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchValue === "" && !isFirstTime) {
      const newPaginationModel = {
        page: 0,
        size: paginationModel.size,
      };

      setPaginationModel(newPaginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFirstTime(false)
      apiCall(paginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  useEffect(() => {
    const attribute = 'Incidents';
    getTicketStatusesByAttribute(attribute)
      .then((res) => {
        setStatusData(res?.reverse().map((item: any) => ({
          value: item?.id,
          label: item?.status
        })));
      })
      .catch((error) => {
        console.error('Error fetching ticket statuses:', error);
      });
  }, []);

  const apiCall = (newPaginationModel: { page: number, size: number }) => {
    setIsDataLoaded(true);

    const fetchData = selectedStatus === "All"
      ? getIncidentsReportByAppGroupIdAndByAppName(appName, appGroupId, newPaginationModel.page, newPaginationModel.size)
      : getIncidentsReportByAppGroupIdAndByAppNameWithStatus(selectedStatus, appName, appGroupId, newPaginationModel.page, newPaginationModel.size);

    fetchData
      .then((data) => {
        const result = data?.incidentReports.map((item: any) => ({
          ...item,
          createdAt: item?.createdAt ? format(new Date(item?.createdAt), "MM/dd/yyyy hh:mm a") : "No date",
        }));

        setSearchTableData(result || []);
        setTotalLength(data?.count || 0);
      })
      .catch((error) => {
        console.log(error);
        setSearchTableData([]);
        setTotalLength(0);
      })
      .finally(() => {
        setIsDataLoaded(false);
      });
  };

  useEffect(() => {
    if (statusData.length > 0) {
      const newPaginationModel = { page: 0, size: paginationModel.size };
      setPaginationModel(newPaginationModel);
      apiCall(newPaginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;

    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        const newPaginationModel = {
          page: 0,
          size: paginationModel.size,
        };

        setPaginationModel(newPaginationModel);

      }, 1000);
    }

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== "" && !isFirstTime) {
      filterApiCall(paginationModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const filterApiCall = (newPaginationModel: { page: number, size: number }) => {
    let filterData: any[] = [];
    setIsDataLoaded(true);
    switch (eventFilterValue) {
      case 0:
        const fetchData = selectedStatus === "All"
          ? getIncidentsReportByAppGroupIdAndByAppNameAndSearchWithIncidentName(searchValue, appName, appGroupId, newPaginationModel.page, newPaginationModel.size)
          : getIncidentsReportByAppGroupIdAndByAppNameWithStatusAndSearchWithIncidentName(searchValue, selectedStatus, appName, appGroupId, newPaginationModel.page, newPaginationModel.size);

        fetchData
          .then((data) => {
            filterData = data?.incidentReports.map((item: any) => {
              return {
                ...item,
                createdAt: item?.createdAt ? format(new Date(item?.createdAt), "MM/dd/yyyy hh:mm a") : "No date",
              };
            });

            setSearchTableData(filterData || []);
            setTotalLength(data?.count || 0);
          })
          .catch((error) => {
            console.log(error);
            setSearchTableData([]);
            setTotalLength(0);
          })
          .finally(() => {
            setIsDataLoaded(false);
          });
        break;

      default:
        setIsDataLoaded(false);
        setSearchTableData([]);
        setTotalLength(0);
        break;
    }
  };

  const applyFilter = () => {
    setSelectedFilter("Subject");
    setSearchValue("");
    setDisplayFilter(false);
  };

  const clearFilter = () => {
    setSearchValue("");
    setDisplayFilter(false);
  };

  const newSupportTicket = () => {
    navigate("/admin/tickets/newsupportticket");
  }

  const editSupportTicket = (id: string) => {
    navigate(`/admin/tickets/editsupportticket/${id}`);
  }

  return (
    <section className="event__container overflow-x-auto">
      <div className="min-w-full flex flex-row justify-between item-center">
        <h1 className="px-4 py-1.5 flex items-center gap-2">
          <MdOutlineSupportAgent fontSize={20} />
          <span className="text-lg">Support Tickets</span>
        </h1>
        <div className="flex gap-2 md:gap-4">
          <button
            className="hidden md:block bg-blue-500 hover:bg-blue-700 px-4 py-1.5 rounded-sm text-white"
            onClick={newSupportTicket}
          >
            New Support Ticket
          </button>
          <button
            className="md:hidden bg-blue-500 hover:bg-blue-700 px-2 py-0.5 rounded-sm text-white"
            onClick={newSupportTicket}
          >
            <IoIosAdd />
          </button>
        </div>
      </div>

      <div className="bg-gray-100 px-2 pt-1.5 mt-2 rounded-lg shadow-md">
        <div className="flex space-x-2 mt-1 mb-1">
          <button
            key="All"
            className={`px-4 py-2 text-sm ${selectedStatus === "All" ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'}`}
            onClick={() => setSelectedStatus("All")}
          >
            All
          </button>

          {statusData.map((status) => (
            <button
              key={status.value}
              className={`px-4 py-2 text-sm ${selectedStatus === status.value ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'}`}
              onClick={() => setSelectedStatus(status.value)}
            >
              {status.label}
            </button>
          ))}
        </div>
      </div>

      <div className="flex space-x-3 justify-between items-center border border-black p-1 md:p-2 mt-4">
        <div className="relative flex space-x-3 justify-between items-center">
          <div className='relative'>
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded-md flex items-center hover:bg-blue-700 focus:outline-none"
              onClick={() => setDisplayFilter(!displayFilter)}
            >
              {selectedFilter}
              <RiArrowDownSFill className="ml-1" />
            </button>

            <div
              className={`${displayFilter ? "block" : "hidden"
                } absolute w-40 ml-1 p-2 bg-white border border-black top-0 left-full`}
            >
              <div className="flex justify-between">
                <label className="text-xs font-bold" htmlFor="fields">
                  Fields
                  <br />
                </label>
                <div
                  onClick={() => setDisplayFilter(false)}
                  className="w-4 h-4 cursor-pointer text-sm text-center items-center"
                >
                  <RxCross1 />
                </div>
              </div>
              <select
                className="w-full text-xs py-1 border mt-1"
                name="fields"
                value={eventFilterValue}
                onChange={(e) =>
                  setEventFilterValue(parseInt(e.target.value))
                }
              >
                <option value="0">Subject</option>
              </select>

              <div className='flex mt-2 justify-between whitespace-nowrap'>
                <button
                  className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                  onClick={() => applyFilter()}
                >
                  Apply Filter
                </button>

                <button
                  className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                  onClick={() => clearFilter()}
                >
                  Clear
                </button>
              </div>

            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-4 text-gray-900">
                <BsSearch />
              </div>

              <input
                type="text"
                placeholder="Search…"
                className="py-1 text-lg pl-2 outline-none"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="my-2" />
      {!isDataLoaded ?
        (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead className="bg-gray-200 whitespace-nowrap">
                  <tr>
                    {heading.map((item, index) => (
                      <th key={index} className="px-6 py-3 text-left text-sm font-semibold text-black">
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="whitespace-nowrap">
                  {searchTableData.length > 0 ? (
                    searchTableData.slice(0, showEntries).map((item, index) => {
                      return (
                        <tr key={index} className="even:bg-blue-50">
                          <td className="px-6 py-4 text-sm">
                            {item.id}
                          </td>
                          <td className="px-6 py-4 text-sm">
                            {item.incidentName}
                          </td>
                          <td className="px-6 py-4 text-sm">
                            {item.assignedToUserName}
                          </td>
                          <td className="px-6 py-4 text-sm">
                            {item.createdAt}
                          </td>
                          <td className="px-6 py-4 text-sm">
                            <span className={`px-3 py-1 rounded-full text-white 
                             ${item.status === "New" ? "bg-orange-500" :
                                item.status === "Resolved" ? "bg-green-500" :
                                  item.status === "Assigned" ? "bg-blue-500" :
                                    item.status === "Pending Assignment" ? "bg-yellow-500" :
                                      item.status === "Closed" ? "bg-gray-500" :
                                        item.status === "Awaiting Input" ? "bg-purple-500" :
                                          item.status === "On Hold" ? "bg-indigo-500" :
                                            "bg-gray-300"}`}>
                              {item.status}
                            </span>
                          </td>

                          <td className="px-6 py-4">
                            <button className="mr-4" title="Edit" onClick={() => editSupportTicket(item.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 fill-blue-500 hover:fill-blue-700"
                                viewBox="0 0 348.882 348.882">
                                <path
                                  d="m333.988 11.758-.42-.383A43.363 43.363 0 0 0 304.258 0a43.579 43.579 0 0 0-32.104 14.153L116.803 184.231a14.993 14.993 0 0 0-3.154 5.37l-18.267 54.762c-2.112 6.331-1.052 13.333 2.835 18.729 3.918 5.438 10.23 8.685 16.886 8.685h.001c2.879 0 5.693-.592 8.362-1.76l52.89-23.138a14.985 14.985 0 0 0 5.063-3.626L336.771 73.176c16.166-17.697 14.919-45.247-2.783-61.418zM130.381 234.247l10.719-32.134.904-.99 20.316 18.556-.904.99-31.035 13.578zm184.24-181.304L182.553 197.53l-20.316-18.556L294.305 34.386c2.583-2.828 6.118-4.386 9.954-4.386 3.365 0 6.588 1.252 9.082 3.53l.419.383c5.484 5.009 5.87 13.546.861 19.03z"
                                  data-original="#000000" />
                                <path
                                  d="M303.85 138.388c-8.284 0-15 6.716-15 15v127.347c0 21.034-17.113 38.147-38.147 38.147H68.904c-21.035 0-38.147-17.113-38.147-38.147V100.413c0-21.034 17.113-38.147 38.147-38.147h131.587c8.284 0 15-6.716 15-15s-6.716-15-15-15H68.904C31.327 32.266.757 62.837.757 100.413v180.321c0 37.576 30.571 68.147 68.147 68.147h181.798c37.576 0 68.147-30.571 68.147-68.147V153.388c.001-8.284-6.715-15-14.999-15z"
                                  data-original="#000000" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  )
                    :
                    (
                      <tr>
                        <td colSpan={10} className="border border-gray-300 px-2 py-1 text-sm text-center font-medium">No data available</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            {
              totalLength > 0 && (
                <Pagination
                  pageOption={[5, 10, 15, 20, 25, 50, 75, 100]}
                  totalLength={totalLength}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  showEntries={showEntries}
                  setShowEntries={setShowEntries}
                />
              )
            }

          </>
        )
        :
        (<div id="loading-colors" className="min-w-full h-[480px] flex flex-col flex-1 justify-center items-center">
          <div className="flex flex-col items-center">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
              role="status"></div>
            <span className="text-black">Loading...</span>
          </div>
        </div>)
      }
    </section>
  )
}

export default SupportTickets