import { RxCross2 } from 'react-icons/rx';
import { FiDownload } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import saveAs from 'file-saver';
import useAttachments from '../hook/useAttachments';
import { ticketBaseURL } from '../config/api';

const AttachmentView = () => {
    const { viewImages, setViewImages, attachments, currentIndex, setCurrentIndex }: any = useAttachments();

    const downloadImage = (index: number) => {
        saveAs(
            `${ticketBaseURL}commonFile/download?name=${attachments[index]?.fileName}`,
            attachments[index]?.fileName
        );
    }

    const prevAttachment = () => {
        if (currentIndex !== 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const nextAttachment = () => {
        if (currentIndex < attachments.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    }

    return (
        <div className={`${viewImages ? "block" : "hidden"} absolute inset-0 before:z-[10000] before:fixed before:inset-0 before:w-full before:min-h-screen before:bg-black/80 flex flex-col`}>
            <div className="w-full flex-1 flex flex-col justify-between relative z-[10001]">

                <div className="flex justify-between items-center cursor-pointer py-2.5 px-6 w-full h-16">
                    <div className="w-1/3 flex justify-start">
                        <span className="font-semibold text-base text-white">{attachments[currentIndex]?.fileName ? attachments[currentIndex]?.fileName : "-"}</span>
                    </div>

                    <div className="w-1/3 flex justify-center">
                        <button
                            type="button"
                            onClick={() => downloadImage(currentIndex)}
                            className="flex justify-center items-center gap-2 text-white text-sm font-semibold outline-none bg-transparent hover:opacity-80">
                            <FiDownload size={24} />
                        </button>
                    </div>

                    <div className="w-1/3 flex justify-end">
                        <button
                            type="button"
                            onClick={() => setViewImages(false)}
                            className="w-8 h-8 flex justify-center items-center gap-2 p-0.5 rounded-full text-white text-sm font-semibold border border-white outline-none bg-transparent hover:opacity-80">
                            <RxCross2 />
                        </button>
                    </div>
                </div>

                <div className="flex justify-between items-center cursor-pointer py-2.5 px-6 w-full flex-1">
                    <div className="w-1/12 flex justify-start">
                        <button
                            type="button"
                            onClick={() => prevAttachment()}
                            className={`${currentIndex === 0 ? "opacity-30 cursor-default" : "opacity-100 hover:opacity-80 cursor-pointer"} w-8 h-8 flex justify-center items-center gap-2 p-0.5 rounded-full text-white text-sm font-semibold border border-white outline-none bg-transparent`}>
                            <FaChevronLeft />
                        </button>
                    </div>

                    <div className="flex w-10/12 h-auto justify-center">
                        <img
                            src={attachments[currentIndex]?.fileName ? `${ticketBaseURL}commonFile/Get?name=${attachments[currentIndex]?.fileName}` : `./images/organization.png`}
                            alt={`leads-attachment-${currentIndex}`}
                            className="rounded-lg text-tertiary text-sm font-semibold outline-none bg-gray-200 border-none w-full h-full object-fit hover:bg-gray-100 active:bg-gray-200"
                        />
                    </div>

                    <div className="w-1/12 flex justify-end">
                        <button
                            type="button"
                            onClick={() => nextAttachment()}
                            className={`${currentIndex < attachments.length - 1 ? "opacity-100 hover:opacity-80 cursor-pointer" : "opacity-30 cursor-default"} w-8 h-8 flex justify-center items-center gap-2 p-0.5 rounded-full text-white text-sm font-semibold border border-white outline-none bg-transparent`}>
                            <FaChevronRight />
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AttachmentView