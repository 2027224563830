import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getIncidentById, put_Incident } from '../../../services/Tickets';
import useAuth from '../../../hook/useAuth';
import { getTicketStatusesByAttribute } from '../../../services/TicketStatus';
import { FaCheck, FaCloudUploadAlt } from 'react-icons/fa';
import { MdDelete, MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { post_Comment, put_Comment, delete_Comment, getComments } from '../../../services/Comment';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import useDelete from '../../../hook/useDelete';
import { appName, ticketBaseURL } from '../../../config/api';
import useAttachments from '../../../hook/useAttachments';
import { format } from 'date-fns';
import { upload_CommonFileSupportTicket } from '../../../services/commonFileUpload';
import { delete_fileUpload, getFileUploads, post_fileUpload } from '../../../services/FileUpload';

const EditSupportTicket = () => {
    const navigate = useNavigate();
    const { auth }: any = useAuth();
    const { id }: any = useParams();
    const { setViewImages, setAttachments, setCurrentIndex }: any = useAttachments();
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [supportTicketData, setSupportTicketData] = useState<any>({
        accountId: 0,
        incidentName: "",
        status: "2",
        requester: "",
        description: "",
        categoryId: 0,
        subcategoryId: 0,
        assignedToUserId: 0,
        assignedToGroupAssignmentId: 0,
        priority: "",
        dueDate: "",
        emailCC: "",
        expectedRevenue: 0,
        groupId: "0",
        appGroupId: "0",
        appName: appName,
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        updatedBy: ""
    });
    const [priorityData, setPriorityData] = useState<any[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isChanges, setIsChanges] = useState<boolean>(true);
    const [comments, setComments] = useState<any[]>([]);
    const [newComment, setNewComment] = useState<string>('');
    const [isAddComment, setIsAddComment] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(true);
    const [isProcessingComment, setIsProcessingComment] = useState<boolean>(false);
    const [isDataUploading, setIsDataUploading] = useState<boolean>(false);
    const [attachmentsValue, setAttachmentsValue] = useState<any[]>([]);
    const [key, setKey] = useState<number>(0);

    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getTicketStatusesByAttribute('Priority')
            .then((res) => {
                setPriorityData(res?.reverse().map((item: any) => ({
                    value: item?.id,
                    label: item?.status
                })));
            })
    }, [])

    useEffect(() => {
        getIncidentById(id)
            .then(res => {
                setSupportTicketData({
                    ...res,
                    status: res.status !== "" && res.status !== "string" ? res.status : "0",
                    priority: res.priority !== "string" && res.priority !== "" ? res.priority : "0",
                    dueDate: res.dueDate?.split("T")[0]
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }, [id]);

    useEffect(() => {
        if (id && isChanges) {
            getComments("Incidents", id)
                .then(res => {
                    setComments(res);
                    setIsChanges(false);
                })
                .catch((error) => console.log(error));
        }
    }, [id, isChanges]);

    useEffect(() => {
        if (id && !isDataUploading) {
            getFileUploads("Incidents", id)
                .then(res => {
                    setAttachmentsValue(res);                  
                })
                .catch((error) => console.log(error));
        }
    }, [id, isDataUploading]);

    const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDataUploading) {
            const selectedFiles = e.target.files as FileList;

            if (selectedFiles.length !== 0) {
                saveImageFile(selectedFiles[0]);
                if (fileRef.current) {
                    fileRef.current.value = '';
                    setKey((prevKey) => prevKey + 1);
                }
            }
        }
    };

    const saveImageFile = (imageFile: File) => {
        setIsDataUploading(true);
        const formData = new FormData();
        formData.append("ImageFile", imageFile);

        upload_CommonFileSupportTicket(formData)
            .then(res => {               
                post_fileUpload({
                    attributeName: "Incidents",
                    attributeId: id,
                    fileName: res,
                    tabName: "Incidents",
                    userId: auth?.id,
                    createdDate: new Date()
                })
                    .then(() => {
                        setIsDataUploading(false);                       
                    })
                    .catch(error => console.error(error))              
            })
            .catch(error => console.error(error))
    }

    const viewImage = (index: number) => {
        setViewImages(true);
        setAttachments(attachmentsValue)
        setCurrentIndex(index);
    }

    const deleteImage = (id: number) => {
        setDeleteData({
            id: id,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this Attachment?`,
            isDelete: (callback: any) => {
                setIsDataUploading(true);
                
                delete_fileUpload(id?.toString())
                .then(() => {
                    setAttachmentsValue(() => attachmentsValue?.filter((item: any) => item?.id !== id));
                })
                .catch(error => console.error(error))
                .finally(() => {
                    callback && callback();
                    setIsDataUploading(false);  
                });                            
            }
        });

        setDeletePopup(true);
    }

    const addComment = () => {
        if (!newComment) return;
        setIsProcessingComment(true);

        const newCommentData = {
            attributeName: "Incidents",
            attributeId: id,
            notes: newComment,
            userId: auth?.id,
            groupId: auth?.groupId,
            createdDate: new Date()
        };

        post_Comment(newCommentData)
            .then(() => {
                setIsChanges(true);
                setNewComment('');
                setIsAddComment(false);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsProcessingComment(false);
            });
    };

    const editComment = (data: any) => {
        setIsProcessingComment(true);

        put_Comment({
            id: data?.id,
            attributeName: data?.attributeName,
            attributeId: data?.attributeId,
            notes: data?.notes,
            userId: data?.userId,
            createdDate: data?.createdDate
        })
            .then(() => {
                setIsChanges(true);
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsProcessingComment(false);
            });
    };

    const deleteComment = (id: string) => {
        setDeleteData({
            id: id,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this comment?`,
            isDelete: (callback: any) => {
                setIsProcessingComment(true);

                delete_Comment(id)
                    .then(() => {
                        setIsChanges(true);
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        callback && callback();
                        setIsProcessingComment(false);
                    });
            }
        });

        setDeletePopup(true);
    };

    const closeModal = () => navigate(-1);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isProcessing && !isProcessingComment) {
            setIsProcessing(true);

            put_Incident({
                ...supportTicketData,
                priority: supportTicketData.priority,
                createdAt: new Date().toISOString(),
                createdBy: auth?.givenname,
                updatedAt: new Date().toISOString(),
                updatedBy: auth?.givenname,
            }, auth?.id)
                .then(() => {
                    setIsProcessing(false);
                    navigate(-1);
                })
                .catch(error => {
                    setIsProcessing(false);
                    console.error(error);
                });
        }
    }

    const isStatusNew = supportTicketData.status === 'Closed';

    return (
        <div className="w-full">
            <div className="my-2">
                <div className="py-8 px-6 relative bg-white">
                    <h2 className="text-xl text-blue-500 font-bold">Edit Support Ticket</h2>
                    <form onSubmit={handleSubmit} className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
                        <div className='col-span-2'>
                            <label className="font-semibold text-sm">Subject</label>
                            <input type='text' placeholder='Enter Subject'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={supportTicketData.incidentName}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, incidentName: e.target.value })}
                                required
                            />
                        </div>

                        <div className='col-span-2'>
                            <label className="font-semibold text-sm">Description</label>
                            <textarea placeholder='Description'
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={supportTicketData.description}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, description: e.target.value })}
                            />
                        </div>

                        <div className='col-span-2'>
                            <label className="font-semibold text-sm">Priority</label>
                            <select
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={supportTicketData.priority}
                                onChange={(e) => {
                                    setSupportTicketData({ ...supportTicketData, priority: e.target.value })
                                }}
                            >
                                {
                                    priorityData.map((item: any, ind: number) => {
                                        return (
                                            <option key={ind} value={item.value}>{item.label}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className='col-span-2'>
                            <span className="text-sm text-secondaryVariant font-semibold">{"Attachment"}</span>
                            <button
                                key={key}
                                type="button"
                                onClick={() => fileRef.current?.click()}
                                className="flex items-center gap-2 px-3 py-1.5 mt-2 rounded text-tertiary text-sm font-semibold border border-tertiary outline-none bg-white hover:opacity-80">
                                {isDataUploading ?
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" className="mr-2 fill-tertiary inline animate-spin"
                                            viewBox="0 0 26.349 26.35">
                                            <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                                            <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                                            <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                                            <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                                            <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                                            <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                                            <path
                                                d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                                                data-original="#000000" />
                                            <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                                        </svg>
                                        Processing...
                                    </>
                                    :
                                    <>
                                        <FaCloudUploadAlt />
                                        Add Attachment
                                    </>
                                }
                            </button>
                            <input
                                ref={fileRef}
                                type="file"
                                accept="image/png, image/jpeg"
                                name="image-choose"
                                className="hidden"
                                onChangeCapture={onFileChangeCapture} />
                        </div>

                        {attachmentsValue?.length !== 0 &&
                            <div className="flex flex-wrap gap-4 col-span-2">
                                {attachmentsValue?.map((_: any, index: number) => (
                                    <div
                                        key={index}
                                        className="relative group">
                                        <div
                                            className="absolute top-1 right-1 bg-slate-200 rounded cursor-pointer invisible group-hover:visible"
                                            onClick={() => deleteImage(_?.id)}>
                                            <MdDelete size={24} />
                                        </div>

                                        <div className=" w-40 h-40 rounded  cursor-pointer"
                                            onClick={() => { viewImage(index) }}>

                                            <img
                                                className="w-full h-24"
                                                src={`${ticketBaseURL}commonFile/Get?name=${_?.fileName}`}
                                                alt={_?.fileName} />

                                            <div className="bg-primaryVariant w-full text-xs flex flex-col justify-center items-center px-1 py-1.5">
                                                <span className="font-semibold">{_?.fileName}</span>
                                                <span>{format(new Date(_?.createdDate), "dd MMM yyyy, KK:mm a")}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }

                        <div className="col-span-2 mt-4">
                            <button
                                type="button"
                                className="flex items-center space-x-2 text-blue-500 hover:text-blue-700 font-semibold"
                                onClick={() => setShowComments(!showComments)}
                            >
                                <span className="material-icons pr-1">
                                    {showComments ? <FaChevronDown /> : <FaChevronRight />}
                                </span>
                                Comments
                            </button>
                        </div>
                        <hr className="col-span-2 border-t border-black" />


                        {showComments && (
                            <>
                                <div className="col-span-2 my-1">
                                    <div className="my-1 px-1">
                                        {comments.map((comment, index) => (
                                            <div key={index} className="flex justify-between items-center my-4">
                                                {!comment.isEdit ? (
                                                    <span className="text-sm w-[90%] text-black">{comment.notes}</span>
                                                ) : (
                                                    <textarea
                                                        className="py-2.5 px-4 text-sm border border-gray-500 text-black w-[90%]"
                                                        value={comment.notes}
                                                        onChange={(e) => setComments(prevComments => {
                                                            const updatedComments = [...prevComments];
                                                            updatedComments[index] = {
                                                                ...updatedComments[index],
                                                                notes: e.target.value
                                                            };
                                                            return updatedComments;
                                                        })}
                                                    />
                                                )}

                                                <div className="space-x-4">
                                                    {!comment.isEdit ? (
                                                        <>
                                                            {!isStatusNew &&
                                                                <button
                                                                    type="button"
                                                                    onClick={() => setComments(prevComments => {
                                                                        const updatedComments = [...prevComments];
                                                                        updatedComments[index].isEdit = true;
                                                                        return updatedComments;
                                                                    })}
                                                                    className="text-blue-600"
                                                                >
                                                                    <MdEdit />
                                                                </button>
                                                            }
                                                            {!isStatusNew &&
                                                                <button
                                                                    type="button"
                                                                    onClick={() => deleteComment(comment.id)}
                                                                    className="text-red-600"
                                                                    disabled={isProcessing || isProcessingComment}
                                                                >
                                                                    <RiDeleteBin6Line />
                                                                </button>
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                type="button"
                                                                onClick={() => editComment(comment)}
                                                                className="text-green-600"
                                                                disabled={isProcessing || isProcessingComment}
                                                            >
                                                                <FaCheck />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => setComments(prevComments => {
                                                                    const updatedComments = [...prevComments];
                                                                    updatedComments[index].isEdit = false;
                                                                    return updatedComments;
                                                                })}
                                                                className="text-gray-600"
                                                            >
                                                                <RxCross2 />
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}


                                        {!isStatusNew && !isAddComment ? (
                                            <button
                                                onClick={() => setIsAddComment(true)}
                                                className="px-6 py-2 text-sm text-white bg-blue-500 hover:bg-blue-700 hover:underline"
                                            >
                                                Add Comment
                                            </button>
                                        ) : !isStatusNew && isAddComment ? (
                                            <div className="flex flex-col my-2">
                                                <textarea
                                                    value={newComment}
                                                    onChange={(e) => setNewComment(e.target.value)}
                                                    className="w-full py-4 px-4 text-sm text-black border border-gray-300 rounded"
                                                    placeholder="Enter your comment..."
                                                />
                                                <div className="flex gap-4 justify-end mt-4">
                                                    <button
                                                        onClick={() => setIsAddComment(false)}
                                                        className="text-sm text-gray-500 hover:text-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={addComment}
                                                        className="text-sm text-blue-500 hover:text-blue-700"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="flex max-sm:flex-col gap-4 !mt-4">
                            <button
                                type="button"
                                className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-6 py-2 min-w-[150px] rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
                                disabled={isProcessing || isProcessingComment}
                            >
                                {isProcessing ? "Processing..." : "Save"}
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditSupportTicket;