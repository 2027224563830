import axios from "axios";
import baseURL from "../config/api";

export const post_Member = (data: any) => {
    return axios({
        url: `${baseURL}member`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_Member = (data: any) => {
    return axios({
        url: `${baseURL}member`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const delete_Member = (primaryKey: string) => {
    return axios({
        url: `${baseURL}member`,
        method: "DELETE",
        params: { id: primaryKey }
    }).then(response => response.data);
}

export const getMemberByGroupId = (groupId: string) => {
    return getFetch(`member/GetMembersByGroup/${groupId}`)
}

export const getMembers = () => {
    return getFetch(`member`)
}

export const getMember = (primaryKey: string) => {
    return getFetch(`member/${primaryKey}`)
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}