
const PrivacyPolicy = () => {
  return (
    <div className="mx-auto p-6 sm:p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-6 text-center">Privacy Policy</h1>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">1. Introduction</h2>
      <p className="text-gray-700 leading-relaxed mb-6">
        <strong>My Church Directory</strong> is committed to protecting your privacy. This privacy policy outlines how we collect, use, and safeguard your information.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">2. Information We Collect</h2>
      <p className="text-gray-700 mb-4">We may collect the following types of information:</p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li><strong>Personal Information:</strong> Your name, email, phone number, and other contact details you provide when signing up or using the app.</li>
        <li><strong>Directory Information:</strong> Information about members, including family relationships, roles within the church, and other relevant details for the church directory.</li>
        <li><strong>Usage Data:</strong> Information about how you use the app, including device type, IP address, and access times.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">3. How We Use Your Information</h2>
      <p className="text-gray-700 mb-4">We use your information for the following purposes:</p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li>To provide and maintain the app and its features.</li>
        <li>To communicate with you regarding updates, announcements, or changes to the app.</li>
        <li>To improve our services based on user feedback and usage patterns.</li>
        <li>To ensure the security of the app and protect against unauthorized access.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">4. Sharing Your Information</h2>
      <p className="text-gray-700 mb-4">We do not sell or rent your personal information to third parties. However, we may share your information in the following situations:</p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li><strong>With Church Leadership:</strong> To maintain the directory and communicate relevant information.</li>
        <li><strong>Legal Requirements:</strong> If required by law or in response to legal requests.</li>
      </ul>

      <p className="text-gray-700 mb-6">
        My Church Directory is committed to the safety of the church and its families. We only collect voluntarily given information, such as name, address, phone number, and email, for the sole purpose of use in the directory, robocalls, and the My Church Directory. Once information is loaded into the directory, only the church administrator controls who gets access to it.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">5. Data Security</h2>
      <p className="text-gray-700 mb-6">
        We implement reasonable security measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">6. Your Rights</h2>
      <p className="text-gray-700 mb-4">You have the right to:</p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li>Access the personal information we hold about you.</li>
        <li>Request corrections to any inaccurate or incomplete information.</li>
        <li>Request deletion of your information, subject to our legal obligations.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">7. Changes to This Privacy Policy</h2>
      <p className="text-gray-700 mb-6">
        We may update this privacy policy from time to time. Any changes will be posted on this page, and your continued use of the app after any changes indicates your acceptance of the updated policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;