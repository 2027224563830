
const Help = () => {
  return (
    <div className="mx-auto p-6 sm:p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-6 text-center">Help</h1>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">How to Log In and Create a Ticket</h2>
      <p className="text-gray-700 mb-4">
        Follow these general steps to log in to the admin panel and create a ticket in the help desk system. Note that specific steps may vary depending on the software used.
      </p>

      <h3 className="text-xl font-semibold text-gray-800 mt-4 mb-2">Steps to Log In and Create a Ticket</h3>

      <div className="space-y-6">
        <div>
          <h4 className="font-semibold text-gray-800 mb-2">1. Open the Help Desk Admin Portal</h4>
          <p className="text-gray-700">Go to the URL of your help desk admin portal: <a href="https://mycdir.com/#/sign-in-admin" className="text-blue-600 underline">https://mycdir.com/#/sign-in-admin</a>.</p>
        </div>

        <div>
          <h4 className="font-semibold text-gray-800 mb-2">2. Log In</h4>
          <ul className="list-disc list-inside text-gray-700 space-y-1">
            <li>Enter your admin username and password.</li>
            <li>Click on the "Log In" button.</li>
          </ul>
        </div>

        <div>
          <h4 className="font-semibold text-gray-800 mb-2">3. Navigate to the Ticket Section</h4>
          <ul className="list-disc list-inside text-gray-700 space-y-1">
            <li>Look for a section labeled “Tickets,” “Support,” or “Help Desk.”</li>
            <li>Click on it to access the ticket management area.</li>
          </ul>
        </div>

        <div>
          <h4 className="font-semibold text-gray-800 mb-2">4. Create a New Ticket</h4>
          <ul className="list-disc list-inside text-gray-700 space-y-1">
            <li>Look for a button or link that says “Create Ticket,” “New Ticket,” or something similar.</li>
            <li>Click on it to open the ticket creation form.</li>
          </ul>
        </div>

        <div>
          <h4 className="font-semibold text-gray-800 mb-2">5. Fill Out Ticket Details</h4>
          <ul className="list-disc list-inside text-gray-700 space-y-1">
            <li><strong>Subject:</strong> Enter a brief summary of the issue.</li>
            <li><strong>Description:</strong> Provide detailed information about the problem or request.</li>
            <li><strong>Priority:</strong> Select the priority level (e.g., Low, Medium, High).</li>
            <li><strong>Assignee:</strong> If applicable, choose the staff member responsible for the ticket.</li>
            <li><strong>Attachments:</strong> Upload any relevant files or screenshots if needed.</li>
          </ul>
        </div>

        <div>
          <h4 className="font-semibold text-gray-800 mb-2">6. Submit the Ticket</h4>
          <ul className="list-disc list-inside text-gray-700 space-y-1">
            <li>Review the information for accuracy.</li>
            <li>Click on the “Submit,” “Create,” or “Save” button to finalize the ticket.</li>
          </ul>
        </div>

        <div>
          <h4 className="font-semibold text-gray-800 mb-2">7. Confirmation</h4>
          <p className="text-gray-700">After submission, you should see a confirmation message or be redirected to the ticket list where you can view the newly created ticket.</p>
        </div>
      </div>

      <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Tips</h2>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
        <li>Check Permissions: Ensure you have the necessary permissions to create tickets in the admin panel.</li>
        <li>Follow Up: Keep track of the ticket’s status and follow up as needed.</li>
      </ul>
    </div>
  );
};

export default Help;