import { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { getPlans } from '../services/Plans';
import HeroSection from '../components/HeroSection';

const FreeTrial = () => {
    const [standardPlan, setStandardPlan] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getPlans()
            .then((data: any) => {
                const standard = data.filter((plan: any) => plan.planName.includes("Standard"));
                standard.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));
                setStandardPlan(standard[0]);
            })
            .catch(error => console.error('Error fetching plans data:', error));
    }, []);

    const handleCheckout = () => {
        if (standardPlan) {
            navigate("/checkout", {
                state: {
                    planId: standardPlan.id,
                    planName: standardPlan.planName,
                    price: 0,
                    billingCycle: 'month',
                    source: 'free-trial'
                }
            });
        } else {
            console.error("Standard plan is not available.");
        }
    };

    return (
        <>
            <HeroSection
                mainHeading="My Church Directory Free Trial"
                backgroundImage="../images/free-trial-mycdir.png"
            // Img Source: canva.com
            />

            <div className="w-full flex flex-col sm:flex-row justify-center py-8 px-4 md:px-0 md:py-16">
                <div className="w-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2 px-4 sm:px-0 py-6">
                    <h1 className="text-[#000] font-semibold text-3xl mb-6 flex items-center">
                        <span>
                            <button className="bg-[#2B3998] text-white p-1.5 px-3 text-lg mr-2">
                                <FaArrowRight />
                            </button>
                            {'Free Trial'}
                        </span>
                    </h1>

                    <div className="flex flex-col">
                        <div className="w-full border border-grey-200 text-center">
                            <h2 className="text-xl mb-4 p-4 bg-gray-200">Standard Membership</h2>
                            <div className='px-5'>
                                <ul className="list-disc ml-4 text-left mb-2">
                                    <li className="mb-3">Unlock essential project management features.</li>
                                    <li className="mb-3">Access core functionalities for efficient project tracking and collaboration.</li>
                                    <li className="mb-3">Ideal for small teams or projects looking for essential project management tools.</li>
                                    <li className="mb-3">Enjoy a one-month free trial with the Standard Membership, with the option to upgrade later.</li>
                                </ul>
                                <button onClick={handleCheckout} className="bg-[#2B3998] text-white px-4 py-2 mb-5 rounded">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FreeTrial;