import { FaChurch } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { RiArrowDownSFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import baseURL from "../../config/api";
import Pagination from "../../components/Pagination";

const Church = () => {
  const navigate = useNavigate();
  const role = Cookies.get("role-my-cdir") || "";
  const groupId = Cookies.get("groupId-my-cdir") || 0;

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchTableData, setSearchTableData] = useState<any[]>([]);
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [churchFilterValue, setChurchFilterValue] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
    page: 0,
    size: 5,
  });
  const [totalLength, setTotalLength] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<string>("Church Name");
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [showEntries, setShowEntries] = useState<number>(5);

  const addChurch = () => {
    navigate("/admin/church/addChurch");
  }

  const editChurch = (id: string) => {
    navigate(`/admin/church/editChurch/${id}`);
  }

  const deleteChurch = (id: string) => {
    navigate(`/admin/church/deleteChurch/${id}`);
  }

  useEffect(() => {
    if (searchValue === "" && !isFirstTime) {
      const newPaginationModel = {
        page: 0,
        size: paginationModel.size,
      };

      setPaginationModel(newPaginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setIsFirstTime(false)
      apiCall(paginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const getGroupForAdmin = () => {
    fetch(
      `${baseURL}group/${groupId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setSearchTableData([data]);
        setTotalLength(1);
        setIsDataLoaded(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(false);
        setSearchTableData([]);
        setTotalLength(0);
      });
  }

  const apiCall = (newPaginationModel: { page: number, size: number }) => {
    setIsDataLoaded(true);
    if (role?.toLowerCase() === "superadmin") {
      fetch(`${baseURL}group/GetGroupReportPaging/${newPaginationModel.page},${newPaginationModel.size}`)
        .then((res) => res.json())
        .then((data) => {
          const result = data.groupReports;
          setSearchTableData(result);
          setTotalLength(data.count);
          setIsDataLoaded(false);

        }).catch((error) => {
          console.log(error);
          setIsDataLoaded(false);
          setSearchTableData([]);
          setTotalLength(0);
        });
    } else {
      getGroupForAdmin();
    }
  }


  useEffect(() => {
    let timeOutId: NodeJS.Timeout;

    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        const newPaginationModel = {
          page: 0,
          size: paginationModel.size,
        };

        setPaginationModel(newPaginationModel);

      }, 1000);
    }

    return () => clearTimeout(timeOutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== "") {
      filteApiCall(paginationModel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel])

  const filteApiCall = (newPaginationModel: { page: number, size: number }) => {
    let filterData: any[] = [];
    setIsDataLoaded(true);
    if (role?.toLowerCase() === "superadmin") {
      switch (churchFilterValue) {
        case 0:
          fetch(
            `${baseURL}group/GetGroupReportSearchWithChurchName/${searchValue},${newPaginationModel.page},${newPaginationModel.size}`
          )
            .then((res) => res.json())
            .then((data) => {
              filterData = data.groupReports;
              setSearchTableData(filterData);
              setTotalLength(data.count);
              setIsDataLoaded(false);

            })
            .catch((error) => {
              console.log(error);
              setIsDataLoaded(false);
              setSearchTableData([]);
              setTotalLength(0);
            });

          break;
        case 1:
          fetch(
            `${baseURL}group/GetGroupReportSearchWithEmailAddress/${searchValue},${newPaginationModel.page},${newPaginationModel.size}`
          )
            .then((res) => res.json())
            .then((data) => {
              filterData = data.groupReports;
              setSearchTableData(filterData);
              setTotalLength(data.count);
              setIsDataLoaded(false);

            })
            .catch((error) => {
              console.log(error);
              setIsDataLoaded(false);
              setSearchTableData([]);
              setTotalLength(0);
            });

          break;
        case 2:
          fetch(
            `${baseURL}group/GetGroupReportSearchWithPhoneNumber/${searchValue},${newPaginationModel.page},${newPaginationModel.size}`
          )
            .then((res) => res.json())
            .then((data) => {
              filterData = data.groupReports;
              setSearchTableData(filterData);
              setTotalLength(data.count);
              setIsDataLoaded(false);

            })
            .catch((error) => {
              console.log(error);
              setIsDataLoaded(false);
              setSearchTableData([]);
              setTotalLength(0);
            });

          break;

      }
    } else {
      getGroupForAdmin();
    }
  }

  const applyFilter = () => {
    setSelectedFilter(() =>
      churchFilterValue === 0 ? "Church Name" : churchFilterValue === 1 ? "Email" : "Phone Number"
    );
    setSearchValue("");
    setDisplayFilter(false);
  };

  const clearFilter = () => {
    setSearchValue("");
    setDisplayFilter(false);
  };


  return (
    <div className="overflow-x-auto">
      <div className="mx-auto">
        <div className="min-w-full flex flex-row justify-between item-center">
          <h1 className="px-4 py-1.5 flex items-center gap-2">
            <FaChurch fontSize={20} />
            <span className="text-lg">Church</span>
          </h1>

          {role?.toLowerCase() === "superadmin" && (
            <button
              className="bg-blue-500 hover:bg-blue-700 px-4 py-1.5 rounded-sm text-white"
              onClick={addChurch}
            >
              Add Church
            </button>
          )}
        </div>
        {role?.toLowerCase() !== "superadmin" && (
          <div className="min-w-full h-0.5 bg-slate-500 my-1" />
        )}

        {role?.toLowerCase() === "superadmin" && (
          <div className="flex space-x-3 justify-between items-center border border-black p-1 md:p-2 mt-4">
            <div className="relative flex space-x-3 justify-between items-center">
              <div className='relative'>
                <button
                  className="bg-blue-500 text-white px-3 py-1 rounded-md flex items-center hover:bg-blue-700 focus:outline-none"
                  onClick={() => setDisplayFilter(!displayFilter)}
                >
                  {selectedFilter}
                  <RiArrowDownSFill className="ml-1" />
                </button>

                <div
                  className={`${displayFilter ? "block" : "hidden"
                    } absolute w-40 ml-1 p-2 bg-white border border-black top-0 left-full`}
                >
                  <div className="flex justify-between">
                    <label className="text-xs font-bold" htmlFor="fields">
                      Fields
                      <br />
                    </label>
                    <div
                      onClick={() => setDisplayFilter(false)}
                      className="w-4 h-4 cursor-pointer text-sm text-center items-center"
                    >
                      <RxCross1 />
                    </div>
                  </div>
                  <select
                    className="w-full text-xs py-1 border mt-1"
                    name="fields"
                    value={churchFilterValue}
                    onChange={(e) =>
                      setChurchFilterValue(parseInt(e.target.value))
                    }
                  >
                    <option value="0">Church Name</option>
                    <option value="1">Email</option>
                    <option value="2">Phone Number</option>

                  </select>

                  <div className='flex mt-2 justify-between whitespace-nowrap'>
                    <button
                      className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                      onClick={() => applyFilter()}
                    >
                      Apply Filter
                    </button>

                    <button
                      className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                      onClick={() => clearFilter()}
                    >
                      Clear
                    </button>
                  </div>

                </div>
              </div>

              <div>
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 text-gray-900">
                    <BsSearch />
                  </div>

                  <input
                    type="text"
                    placeholder="Search…"
                    className="py-1 text-lg pl-2 outline-none"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

        )}

        {!isDataLoaded ? (
          <>
            {
              searchTableData.length > 0 ? (
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 max-md:justify-center mt-5 md:ml-10">
                  {
                    searchTableData.slice(0, showEntries).map((item, index) => (
                      <div key={index} className="flex items-center max-sm:flex-col">
                        <div className="w-full sm:h-40 rounded object-contain object-top">
                          <img src={`${baseURL}commonFile/Get?name=${item?.imagePath}`}
                            alt="groupName" className="w-full h-full"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src="./images/img_placeholder1.jpg";
                            }}
                            />
                        </div>
                        <div className="w-full p-4 sm:ml-4">
                          <h4 className="text-base font-extrabold">{item.name}</h4>
                          <p className="text-xs mt-1 font-bold">{item.discription}</p>
                          <p className="text-xs mt-1">{item.country},{item.state}</p>
                          <p className="text-xs mt-1">{item.city},{item.street}</p>
                          <p className="text-xs mt-1">{item.emailAddress}</p>
                          <p className="text-xs mt-1">{item.phoneNumber}</p>
                          <div className="space-x-4 mt-4">
                            <button type="button"
                              className="w-7 h-7 inline-flex items-center max-sm:flex-col justify-center"
                              onClick={() => editChurch(item.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 fill-blue-500 hover:fill-blue-700"
                                viewBox="0 0 348.882 348.882">
                                <path
                                  d="m333.988 11.758-.42-.383A43.363 43.363 0 0 0 304.258 0a43.579 43.579 0 0 0-32.104 14.153L116.803 184.231a14.993 14.993 0 0 0-3.154 5.37l-18.267 54.762c-2.112 6.331-1.052 13.333 2.835 18.729 3.918 5.438 10.23 8.685 16.886 8.685h.001c2.879 0 5.693-.592 8.362-1.76l52.89-23.138a14.985 14.985 0 0 0 5.063-3.626L336.771 73.176c16.166-17.697 14.919-45.247-2.783-61.418zM130.381 234.247l10.719-32.134.904-.99 20.316 18.556-.904.99-31.035 13.578zm184.24-181.304L182.553 197.53l-20.316-18.556L294.305 34.386c2.583-2.828 6.118-4.386 9.954-4.386 3.365 0 6.588 1.252 9.082 3.53l.419.383c5.484 5.009 5.87 13.546.861 19.03z"
                                  data-original="#000000" />
                                <path
                                  d="M303.85 138.388c-8.284 0-15 6.716-15 15v127.347c0 21.034-17.113 38.147-38.147 38.147H68.904c-21.035 0-38.147-17.113-38.147-38.147V100.413c0-21.034 17.113-38.147 38.147-38.147h131.587c8.284 0 15-6.716 15-15s-6.716-15-15-15H68.904C31.327 32.266.757 62.837.757 100.413v180.321c0 37.576 30.571 68.147 68.147 68.147h181.798c37.576 0 68.147-30.571 68.147-68.147V153.388c.001-8.284-6.715-15-14.999-15z"
                                  data-original="#000000" />
                              </svg>
                            </button>

                            {role?.toLowerCase() === "superadmin" && (
                              <button type="button"
                                className="w-7 h-7 inline-flex items-center max-sm:flex-col justify-center rounded-full"
                                onClick={() => deleteChurch(item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 fill-red-500 hover:fill-red-700" viewBox="0 0 24 24">
                                  <path
                                    d="M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z"
                                    data-original="#000000" />
                                  <path d="M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z"
                                    data-original="#000000" />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )
                :
                (
                  <div id="loading-colors" className="min-w-full h-[80px] flex flex-col flex-1 justify-center items-center">
                    <div className="flex flex-col items-center">
                      <span className="text-black">No Data Found</span>
                    </div>
                  </div>
                )

            }

            {
              role?.toLowerCase() === "superadmin" && totalLength > 0 && (
                <Pagination
                  pageOption={[5, 10, 15, 20, 25, 50, 75, 100]}
                  totalLength={totalLength}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  showEntries={showEntries}
                  setShowEntries={setShowEntries}
                />
              )
            }
          </>
        ) : (
          <div id="loading-colors" className="min-w-full h-[480px] flex flex-col flex-1 justify-center items-center">
            <div className="flex flex-col items-center">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
                role="status"></div>
              <span className="text-black">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Church