import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { post_Incident } from '../../../services/Tickets';
import useAuth from '../../../hook/useAuth';
import { getTicketStatusesByAttribute } from '../../../services/TicketStatus';
import { upload_CommonFileSupportTicket } from '../../../services/commonFileUpload';
import { post_fileUpload } from '../../../services/FileUpload';
import useDelete from '../../../hook/useDelete';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { format } from 'date-fns';
import useAttachments from '../../../hook/useAttachments';
import { appName, ticketBaseURL } from '../../../config/api';

const NewSupportTicket = () => {
    const navigate = useNavigate();
    const { auth }: any = useAuth();
    const { setViewImages, setAttachments, setCurrentIndex }: any = useAttachments();
    const { setDeletePopup, setData: setDeleteData }: any = useDelete();
    const [supportTicketData, setSupportTicketData] = useState<any>({
        accountId: 0,
        incidentName: "",
        status: "2",
        requester: "",
        description: "",
        categoryId: 0,
        subcategoryId: 0,
        assignedToUserId: 0,
        assignedToGroupAssignmentId: 0,
        priority: "",
        dueDate: "",
        emailCC: "",
        expectedRevenue: 0,
        groupId: "0",
        appGroupId: "0",
        appName: appName,
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        updatedBy: ""
    });
    const [priorityData, setPriorityData] = useState<any[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const groupId = Cookies.get("groupId-my-cdir") || "";
    const [isDataUploading, setIsDataUploading] = useState<boolean>(false);
    const [attachmentsValue, setAttachmentsValue] = useState<any[]>([]);
    const [key, setKey] = useState<number>(0);

    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getTicketStatusesByAttribute('Priority')
            .then((res) => {
                const data =  res?.reverse()
                ?.map((item: any) => {
                    return {
                        value: item?.id,
                        label: item?.status
                    }
                });
                setPriorityData(() => data);

                setSupportTicketData({ ...supportTicketData, priority: data?.length > 0 ? data[0]?.value : 0, });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDataUploading) {
            const selectedFiles = e.target.files as FileList;

            if (selectedFiles.length !== 0) {
                saveImageFile(selectedFiles[0]);
                if (fileRef.current) {
                    fileRef.current.value = '';
                    setKey((prevKey) => prevKey + 1);
                }
            }
        }
    };

    const saveImageFile = (imageFile: File) => {
        setIsDataUploading(true);
        const formData = new FormData();
        formData.append("ImageFile", imageFile);

        upload_CommonFileSupportTicket(formData)
            .then(res => {
                setIsDataUploading(false);
                setAttachmentsValue((prevValue) => ([...prevValue, {
                    fileName: res,
                    createdDate: new Date()
                }]));
            })
            .catch(error => console.error(error))
    }

    const viewImage = (index: number) => {
        setViewImages(true);
        setAttachments(attachmentsValue)
        setCurrentIndex(index);
    }

    const deleteImage = (id: number) => {
        setDeleteData({
            id: 0,
            tilte: `Delete`,
            summary: `Are you sure you want to delete this Attachment?`,
            isDelete: (callback: any) => {               
                setAttachmentsValue(() => attachmentsValue?.filter((_: any, index: number) => id !== index));
                callback && callback();                
            }
        });

        setDeletePopup(true);
    }

    const closeModal = () => navigate(-1);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isProcessing) {
            setIsProcessing(true);

            post_Incident({
                ...supportTicketData,
                status: "2",
                requester: auth?.givenname,
                priority: supportTicketData.priority,
                appGroupId: groupId,
                appName: appName,
                createdAt: new Date().toISOString(),
                createdBy: auth?.givenname,
                updatedAt: new Date().toISOString(),
                updatedBy: auth?.givenname,
            }, auth?.id)
                .then((ticketId) => {
                    return fileUpload(ticketId);
                })
                .then(() => {
                    closeModal();
                    setIsProcessing(false);
                })
                .catch((error) => {
                    console.error('Error creating ticket or uploading files:', error);
                    setIsProcessing(false);
                });
        }
    }

    const fileUpload = (ticketId: any) => {
        return new Promise<void>((resolve, reject) => {
            if (attachmentsValue?.length > 0) {
                let count = 0;
                attachmentsValue?.forEach((attachment: any) => {
                    post_fileUpload({
                        attributeName: "Incidents",
                        attributeId: ticketId,
                        fileName: attachment?.fileName,
                        tabName: "Incidents",
                        userId: auth?.id,
                        createdDate: new Date()
                    })
                        .then(() => {
                            count++;
                            if (count === attachmentsValue.length) {
                                resolve();
                            }
                        })
                        .catch((error) => {
                            console.error('File upload failed:', error);
                            reject(error);
                        });
                });
            } else {
                resolve();
            }
        });
    }

    return (
        <div className="w-full">
            <div className="my-2">
                <div className="py-8 px-6 relative bg-white">
                    <h2 className="text-xl text-blue-500 font-bold">Create Support Ticket</h2>
                    <form onSubmit={handleSubmit} className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
                        <div className='col-span-2'>
                            <label className="font-semibold text-sm">Subject</label>
                            <input type='text' placeholder='Enter Subject'
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={supportTicketData.incidentName}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, incidentName: e.target.value })}
                                required
                            />
                        </div>

                        <div className='col-span-2'>
                            <label className="font-semibold text-sm">Description</label>
                            <textarea placeholder='Description'
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={supportTicketData.description}
                                onChange={(e) => setSupportTicketData({ ...supportTicketData, description: e.target.value })}
                            />
                        </div>

                        <div className='col-span-2'>
                            <label className="font-semibold text-sm">Priority</label>
                            <select
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={supportTicketData.priority}
                                onChange={(e) => {
                                    setSupportTicketData({ ...supportTicketData, priority: e.target.value })
                                }}
                            >
                                {
                                    priorityData?.map((item: any, ind: number) => {
                                        return (
                                            <option key={ind} value={item.value}>{item.label}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className='col-span-2'>
                            <span className="text-sm text-secondaryVariant font-semibold">{"Attachment"}</span>
                            <button
                                key={key}
                                type="button"
                                onClick={() => fileRef.current?.click()}
                                className="flex items-center gap-2 px-3 py-1.5 mt-2 rounded text-tertiary text-sm font-semibold border border-tertiary outline-none bg-white hover:opacity-80">
                                {isDataUploading ?
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" className="mr-2 fill-tertiary inline animate-spin"
                                            viewBox="0 0 26.349 26.35">
                                            <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                                            <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                                            <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                                            <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                                            <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                                            <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                                            <path
                                                d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                                                data-original="#000000" />
                                            <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                                        </svg>
                                        Processing...
                                    </>
                                    :
                                    <>
                                        <FaCloudUploadAlt />
                                        Add Attachment
                                    </>
                                }
                            </button>
                            <input
                                ref={fileRef}
                                type="file"
                                accept="image/png, image/jpeg"
                                name="image-choose"
                                className="hidden"
                                onChangeCapture={onFileChangeCapture} />
                        </div>

                        {attachmentsValue?.length !== 0 &&
                            <div className="flex flex-wrap gap-4 col-span-2">
                                {attachmentsValue?.map((_: any, index: number) => (
                                    <div
                                        key={index}
                                        className="relative group">
                                        <div
                                            className="absolute top-1 right-1 bg-slate-200 rounded cursor-pointer invisible group-hover:visible"
                                            onClick={() => deleteImage(index)}>
                                            <MdDelete size={24} />
                                        </div>

                                        <div className=" w-40 h-40 rounded  cursor-pointer"
                                            onClick={() => { viewImage(index) }}>

                                            <img
                                                className="w-full h-24"
                                                src={`${ticketBaseURL}commonFile/Get?name=${_?.fileName}`}
                                                alt={_?.fileName} />

                                            <div className="bg-primaryVariant w-full text-xs flex flex-col justify-center items-center px-1 py-1.5">
                                                <span className="font-semibold">{_?.fileName}</span>
                                                <span>{format(new Date(_?.createdDate), "dd MMM yyyy, KK:mm a")}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }

                        <div className="flex max-sm:flex-col gap-4 !mt-4">
                            <button
                                type="button"
                                className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-6 py-2 min-w-[150px] rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
                            >
                                {isProcessing ?
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                                            viewBox="0 0 26.349 26.35">
                                            <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                                            <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                                            <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                                            <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                                            <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                                            <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                                            <path
                                                d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                                                data-original="#000000" />
                                            <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                                        </svg>
                                        Processing...
                                    </>
                                    :
                                    "Save"
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewSupportTicket