import { ReactNode, createContext, useState } from 'react'

const AttachmentContext = createContext({});

export const AttachmentProvider = ({ children }: { children: ReactNode }) => {
    const [viewImages, setViewImages] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const value = { viewImages, setViewImages, attachments, setAttachments, currentIndex, setCurrentIndex };

    return (
        <AttachmentContext.Provider value={value}>
            {children}
        </AttachmentContext.Provider>
    )
}

export default AttachmentContext